<template>
    <div>
        <form v-on:submit.prevent="forgetPassword">
            <div class="text-2xl uppercase mb-4">
                {{ $t('Password forget') }}
            </div>

            <div class="p-field mb-4">
                <label class="block text-600 mb-2">
                    {{ $t('E-Mail-Address') }}
                </label>
                <input-text
                    v-model="email"
                    class="w-full"
                    type="email"
                />
                <small class="p-invalid block mt-2" v-for="message in errors.email" :key="message">
                    {{ message }}
                </small>
            </div>

            <Button
                type="submit"
                :label="$t('Password reset')"
                class="w-full"
            />
        </form>
    </div>
</template>

<script>
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';
    import util from '../../plugins/util';

    export default {
        components: {
            Button,
            InputText,
        },
        data() {
            return {
                email: '',
                message: null,
                errors: {},
            }
        },
        methods: {
            forgetPassword() {
                this.$store.commit('incrementLoadingIndex');

                this.$http.post(
                    'actions/password-forget',
                    {
                        email: this.email,
                    }
                ).then(() => {
                    this.$store.commit('decrementLoadingIndex');
                    this.$toast.add({
                        severity: 'success',
                        summary: this.$t('Success'),
                        detail: this.$t('The password has been reset successfully. You will receive an email shortly.'),
                        life: 3000
                    });
                    this.$router.push('/login');
                }, (response) => {
                    console.log(response.status);
                    if (response.status === 422) {
                        this.errors = util.mapConstraints(response.body);
                    } else if (parseInt(response.status) === 403) {
                        this.errors = {
                            email: [
                                this.$t('Unfortunately, the password was reset recently.')
                            ]
                        }
                    }
                    this.$store.commit('decrementLoadingIndex');
                });
            }
        }
    }
</script>
